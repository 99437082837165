import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-md rounded-lg p-6">
                <h1 className="text-3xl font-extrabold text-gray-900 mb-6 text-center">個人情報の取扱いについて</h1>
                <div className="space-y-6 text-gray-700">
                    <section>
                        <h2 className="text-xl font-bold mb-2">1. 個人情報の収集と利用目的</h2>
                        <p>本アプリでは、以下の目的でユーザーの個人情報を収集・利用いたします。</p>
                        <ul className="list-disc pl-6 mt-2">
                            <li>本アプリの運営、機能提供</li>
                            <li>カスタムカレンダー同期や学会情報の管理</li>
                            <li>サポートやご案内のための連絡</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-bold mb-2">2. 収集する個人情報</h2>
                        <p>本アプリで収集される個人情報は、以下の通りです。</p>
                        <ul className="list-disc pl-6 mt-2">
                            <li>メールアドレス（認証時）</li>
                            <li>カレンダー同期に必要なGoogleアカウント情報</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-bold mb-2">3. 個人情報の安全管理</h2>
                        <p>ユーザーの個人情報は、不正アクセスや漏えいを防ぐため、厳重なセキュリティ対策を講じています。</p>
                    </section>

                    <section>
                        <h2 className="text-xl font-bold mb-2">4. 第三者提供</h2>
                        <p>ユーザーの同意がない限り、個人情報を第三者に提供することはありません。ただし、法令に基づく場合を除きます。</p>
                    </section>

                    <section>
                        <h2 className="text-xl font-bold mb-2">5. 利用停止・削除</h2>
                        <p>
                            ユーザーは、いつでも個人情報の利用停止や削除を請求することができます。リクエストは
                            <a href="https://forms.gle/ipFExKdrZLWwyjX18" className="text-blue-600 hover:underline ml-1">こちらのフォーム</a>
                            からお受けいたします。
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-bold mb-2">6. 改訂</h2>
                        <p>本ポリシーは、必要に応じて改訂されます。改訂内容は、本ページにて随時お知らせいたします。</p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
