import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
// import { google } from 'googleapis';
// import { GaxiosResponse } from 'gaxios';
import { Route, Routes, Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy'; // プライバシーポリシーページのインポート

function App() {
  // 学会名のリストを保持するための状態
  const [confs, setConfs] = useState([]);
  const [selectedConfs, setSelectedConfs] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState(null); // 選択されたカレンダーIDの状態
  const [syncing, setSyncing] = useState(false);
  const [progress, setProgress] = useState(0);
  const { isAuthenticated, isLoading } = useAuth0();

  const fetchCalendars = useGoogleLogin({
    onSuccess: tokenResponse => loadCalendars(tokenResponse),
    onError: () => console.log('Login Failed'),
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    // 他に必要なオプションがあればここに追加します
  });

  // ISO 8601形式に変換し、日本のタイムゾーンを適用する関数
  function toRFC3339String(dateStr) {
    // 日付文字列のスラッシュをハイフンに置換
    const normalizedDateStr = dateStr.replace(/\//g, '-');
    // 日付文字列にデフォルトの時間を追加して日本時間の日時を表す文字列を作成
    const dateTimeStr = `${normalizedDateStr}T00:00:00+09:00`;
    // Dateオブジェクトを生成
    const dateTime = new Date(dateTimeStr);
    // RFC3339形式の文字列に変換
    return dateTime.toISOString();
  }

  const fetchSync = useGoogleLogin({
    onSuccess: tokenResponse => {
      setSyncing(true);
      setProgress(0);
      syncCalendars(tokenResponse, selectedConfs, selectedCalendarId);
      // 進捗状況を更新するロジックを仮定（本来はAPIの応答による）
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 20;
          }
          clearInterval(interval);
          setSyncing(false);
          alert('同期が完了しました！Google カレンダーをご確認ください。');
          return 100;
        });
      }, 1000);
    },
    // syncCalendars(tokenResponse)
    onError: () => console.log('Login Failed'),
    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets.readonly',
    // 他に必要なオプションがあればここに追加します
  });

  // 注意: この関数はgapi.clientが初期化された後に呼び出す必要があります。
  const syncCalendars = (tokenResponse, confs, calendarId) => {
    const token = tokenResponse.access_token;
    console.log('Syncing calendars:', confs, calendarId);
    // console.log('Token:', token);
    // Google Sheets APIからスプレッドシートのデータを取得
    // const sheetApiUrl = `https://sheets.googleapis.com/v4/spreadsheets/YOUR_SPREADSHEET_ID/values/学会予定!A:F`;
    const sheetApiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}/values/学会予定!A:G`;
    fetch(sheetApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        // スプレッドシートのデータを元にカレンダーにイベントを追加
        const events = data.values; // 仮にスプレッドシートのデータがこの形式であるとします
        events.forEach(event => {
          let [id, title, start, end, location, hp, conf] = event;
          if (confs.includes(conf)) { // 選択された学会名と一致する場合のみ処理
            const calendarApiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;
            start = toRFC3339String(start);
            end = toRFC3339String(end);
            const eventData = {
              summary: title,
              location,
              description: hp,
              start: { dateTime: start },
              end: { dateTime: end },
            };

            const eventsCheckUrl = `${calendarApiUrl}?timeMin=${start}&timeMax=${end}&q=${title}`;
            fetch(eventsCheckUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache', // キャッシュ無効化
              },
            })
              .then(response => response.json())
              .then(existingEventsData => {
                const eventExists = existingEventsData.items && existingEventsData.items.some(eventItem => {
                  // イベントの詳細を厳密に比較
                  return eventItem.status !== 'cancelled'
                  //  eventItem.summary === title &&
                  //  eventItem.start.dateTime === start &&
                  //  eventItem.end.dateTime === end;
                });

                if (!eventExists) {

                  // カレンダーAPIにイベントを追加
                  fetch(calendarApiUrl, {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(eventData),
                  })
                    .then(response => response.json())
                    .then(data => console.log('Event created: ', data))
                    .catch(error => console.error('Error adding event to calendar:', error));
                } else {
                  console.log('Event already exists: ', existingEventsData.items[0].summary);
                }
              })
          }
        });
      })
      .catch(error => console.error('Error fetching sheet data:', error));
  };


  // カレンダーをクリックしたときの処理
  const handleCalendarSelect = (id) => {
    setSelectedCalendarId(id); // 選択されたカレンダーIDを更新
  };

  // カレンダーデータをロードする関数
  const loadCalendars = (tokenResponse) => {
    const token = tokenResponse.access_token; // トークンを正しく取得する
    const apiUrl = 'https://www.googleapis.com/calendar/v3/users/me/calendarList';

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setCalendars(data.items); // カレンダーデータを状態にセット
      })
      .catch(error => console.error('Error fetching calendars:', error));
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // 学会名を取得する非同期関数
  const fetchConferences = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_SERVER;
      console.log('Url:', `${baseUrl}/api/get_confs`);
      const response = await fetch(`${baseUrl}/api/get_confs`, { mode: 'cors' }); // CORSエラーを回避
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setConfs(data);
    } catch (error) {
      console.error("Fetching confs failed:", error);
    }
  };

  // コンポーネントがマウントされた時にAPIから学会名を取得
  useEffect(() => {
    fetchConferences();
  }, []); // 空の依存配列を指定して、コンポーネントのマウント時にのみ実行

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const handleCategorySelection = (category) => {
    // カテゴリーに属する全ての項目が既に選択されているか確認
    const isEverySelected = confs[category].every(conf => selectedConfs.includes(conf));

    if (isEverySelected) {
      // カテゴリーで一括選択された全てを選択解除する場合
      setSelectedConfs(prevSelectedConfs => prevSelectedConfs.filter(conf => !confs[category].includes(conf)));
    } else {
      // カテゴリーで一括選択する場合（一部または全てが未選択の場合）
      // 重複を避けるために新しいセットを作成し、それを配列に変換して設定
      const newSelectedConfs = [...new Set([...selectedConfs, ...confs[category]])];
      setSelectedConfs(newSelectedConfs);
    }
  };


  const handleConferenceSelect = (conf) => {
    setSelectedConfs(prev => {
      if (prev.includes(conf)) {
        return prev.filter(item => item !== conf);
      } else {
        return [...prev, conf];
      }
    });
  };

  return (
    <div className="App">
      <>
        <header className="bg-blue-500 text-white p-6">
          <div className="container mx-auto flex justify-between items-center">
            <Link to="/">
              <h1 className="text-xl font-semibold cursor-pointer hover:underline">
                学会カレンダー
              </h1>
            </Link>
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </div>
        </header>


        {/* Routes に変更 */}
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? (
              <>
                <main className="my-4  min-h-screen">
                  {/* 冒頭文 */}
                  <section className="text-gray-700 body-font">
                    <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
                      <div className="text-center lg:w-2/3 w-full">
                        {/* <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                    最新の学会情報をGoogleカレンダーに同期する
                  </h1> */}
                        <p className="mb-8 leading-relaxed">
                          下記の2ステップに従ってフォームを送信することで、あなたのGoogleカレンダーに最新の学会情報が追加されます。
                        </p>
                      </div>
                    </div>
                  </section>
                  {/* 見出し */}
                  <div className="container mx-auto text-center mt-8 mb-4">
                    <h2 className="text-2xl font-semibold">
                      {currentStep === 1 && "Step 1: 同期したい学会情報の選択"}
                      {currentStep === 2 && "Step 2: 同期先のGoogleカレンダーを一つ選択"}
                    </h2>
                  </div>
                  {/* 本文 */}
                  <div className="container mx-auto p-4">
                    <div className="shadow-lg rounded-lg bg-white max-w-2xl mx-auto px-4">
                      {currentStep === 1 && (
                        <div className="mb-4">
                          {/* 学会情報選択のUI */}
                          {Object.keys(confs).map((category) => (
                            <div key={category} className="mb-4">
                              <div className="flex items-center justify-between mb-2 p-4 bg-white shadow-lg rounded-lg">
                                <span className="font-semibold text-lg text-gray-800">{category}</span>
                                <div className="flex items-center">
                                  <button
                                    onClick={() => toggleCategory(category)}
                                    className="text-blue-500 hover:text-blue-700 mr-2"
                                  >
                                    {expandedCategories[category] ? '閉じる' : '開く'}
                                  </button>
                                  <label className="inline-flex items-center cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                      checked={confs[category].every(conf => selectedConfs.includes(conf))}
                                      onChange={() => handleCategorySelection(category)}
                                    />
                                  </label>
                                </div>
                              </div>
                              {expandedCategories[category] && (
                                <ul className="list-disc pl-5 space-y-2">
                                  {confs[category].map((conf, index) => (
                                    <li key={index} className="mb-2 bg-gray-100 p-2 rounded-md hover:bg-gray-200 flex items-center justify-between">
                                      <label className="flex items-center space-x-2">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-blue-600"
                                          checked={selectedConfs.includes(conf)}
                                          onChange={() => handleConferenceSelect(conf)}
                                        />
                                        <span>{conf}</span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}

                        </div>
                      )}
                      {currentStep === 2 && (
                        <div>
                          <button
                            onClick={() => fetchCalendars()}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                          >
                            Googleカレンダーを読み込む
                          </button>

                          {/* カレンダー名の一覧表示 */}
                          <div className="max-h-[500px] overflow-y-auto">
                            <ul className="space-y-4 mt-8">
                              {calendars.map(calendar => (
                                <li
                                  key={calendar.id}
                                  className={`flex items-center justify-between text-lg text-gray-800 bg-white shadow-lg rounded-lg p-4 hover:bg-blue-100 transition duration-200 ease-in-out ${selectedCalendarId === calendar.id ? 'bg-blue-100' : ''}`}
                                  onClick={() => handleCalendarSelect(calendar.id)}
                                >
                                  <span>{calendar.summary}</span>
                                  {selectedCalendarId === calendar.id ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-300 rounded-full"></div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>



                        </div>

                      )}
                    </div>
                  </div>
                  {/* ナビゲーションボタン */}
                  <div className={`container mx-auto flex ${currentStep > 1 ? 'justify-between' : 'justify-end'} mt-4`}>
                    {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                      >
                        戻る
                      </button>
                    )}

                    {currentStep < 2 && (
                      <button
                        onClick={nextStep}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                      >
                        次へ
                      </button>
                    )}

                    {
                      currentStep === 2 && (
                        <button
                          onClick={() => fetchSync()} // fetchSyncを呼び出し
                          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                          disabled={syncing}
                        >
                          {syncing ? '同期中...' : '同期'}
                        </button>
                      )
                    }
                    {
                      syncing && (
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 flex justify-center items-center">
                          <div className="bg-white p-4 rounded shadow-lg text-center">
                            <h2 className="mb-2">同期中です。そのまましばらくお待ちください。</h2>
                            <div className="w-full bg-gray-300 rounded-full h-4">
                              <div
                                className="bg-green-500 h-4 rounded-full"
                                style={{ width: `${progress}%` }}
                              ></div>
                            </div>
                            <p className="mt-2">{progress}%</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </main>
              </>
            ) : (
              <>
                <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                  <h1 className="text-4xl font-bold mb-8">Washimo</h1>
                </main>
              </>
            )}
          />
          {/* 他のルート */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        <footer className="bg-gray-200 py-6">
          <div className="container mx-auto text-center">
            <Link to="/privacy-policy" className="text-blue-500 hover:text-blue-700">
              個人情報の取扱いについて
            </Link>
          </div>
        </footer>

      </>
    </div>
  );
}

export default App;
